import './themes/base.css';

function getHeaderThemeNamePrivate(env) {
    switch (env) {
        case 'pek': {
            return 'pek';
        }
        default: {
            return 'mycom';
        }
    }
}

export const headerThemeNamePrivate = getHeaderThemeNamePrivate(process.env.COMPANY);

// eslint-disable-next-line import/no-dynamic-require
require(/* webpackMode: eager */ `./themes/${process.env.COMPANY}.js`); /* НИКОГДА ЭТО НЕ ТРОГАЙ */
/* ТУТ ВСЕ ДОЛЖНО БЫТЬ ТАК, ЕСЛИ ТЫ ДАЖЕ ДУМАЕШЬ ПО-ДРУГОМУ */

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./HelveticaNeueCyr-Medium.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./HelveticaNeueCyr-Medium.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./HelveticaNeueCyr-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./HelveticaNeueCyr-Bold.eot");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./HelveticaNeueCyr-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./HelveticaNeueCyr-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./HelveticaNeueCyr-Light.eot");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./HelveticaNeueCyr-Light.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("./HelveticaNeueCyr-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_9___ = require("./HelveticaNeueCyr-Roman.ttf");
var ___CSS_LOADER_URL_IMPORT_10___ = require("./HelveticaNeueCyr-Roman.woff2");
var ___CSS_LOADER_URL_IMPORT_11___ = require("./HelveticaNeueCyr-Roman.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
exports.push([module.id, "@font-face{font-family:HelveticaNeueCyr;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");src:local(\"HelveticaNeueCyr-Medium\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");font-weight:500;font-style:normal}@font-face{font-family:HelveticaNeueCyr;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");src:local(\"HelveticaNeueCyr-Bold\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"truetype\");font-weight:700;font-style:normal}@font-face{font-family:HelveticaNeueCyr;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ");src:local(\"HelveticaNeueCyr-Light\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format(\"truetype\");font-weight:300;font-style:normal}@font-face{font-family:HelveticaNeueCyr;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_12___ + ");font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_13___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_14___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_12___ + ") format(\"truetype\")}", ""]);
// Exports
module.exports = exports;
